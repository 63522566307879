import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';

export const getValueFromLocalStorage = (key: string) => window.localStorage.getItem(key) as string;
export const setValueToLocalStorage = (key: string, param: string) => {
    window.localStorage.setItem(key, param);
};
export const removeValueFromLocalStorage = (key: string) => {
    window.localStorage.removeItem(key);
};

export const removeQuestValuesFromLocalStorage = () => {
    removeValueFromLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_QUEST_ACTION);
    removeValueFromLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_QUEST_COOLDOWN);
    removeValueFromLocalStorage(LOCAL_STORAGE_KEYS.RETRY_TIME_MOTIVATION_SHOWN);
    removeValueFromLocalStorage(LOCAL_STORAGE_KEYS.CORRECT_STREAK_COUNT);
    removeValueFromLocalStorage(LOCAL_STORAGE_KEYS.INCORRECT_STREAK_COUNT);
    removeValueFromLocalStorage(LOCAL_STORAGE_KEYS.INCORRECT_STREAK_COUNT);
    removeValueFromLocalStorage(LOCAL_STORAGE_KEYS.PRE_ROUND_COUNTDOWN);
};
